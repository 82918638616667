@use '../../styles/shared/include' as *;

.repeatableTextSection {
	background: $white;
	scroll-behavior: smooth;

	article {
		position: relative;
		h3 {
			font-size: rem(30);
			margin-bottom: rem(24);
			font-weight: 300;
			line-height: 120%;
			@media #{$md} {
				font-size: rem(35);
			}
			@media #{$lg} {
				font-size: rem(48);
			}
		}
		p {
			margin-bottom: rem(24);
		}
		padding: rem(30);
		border-bottom: rem(1) solid $periwinkleGrey;
		@media #{$md} {
			flex: 1;
			border-bottom: none;
			padding: rem(60);
			&::after {
				content: '';
				position: absolute;
				height: 100%;
				width: rem(1);
				background-color: $periwinkleGrey;
				top: 0;
				right: 0;
			}
		}
		@media #{$xl} {
			padding: rem(112) rem(120);
		}
	}
	@media #{$md} {
		display: flex;
		flex-direction: row;
	}
}
