@use '../../styles/shared/include' as *;

.mediumDescriptionBanner {
	display: flex;
	flex-direction: column;
	background-color: $deepBlue;
	@media #{$md} {
		flex-direction: row;
	}

	.textContent {
		width: 100%;
		padding: rem(48) rem(20);
		text-align: left;
		color: $white;
		h1 {
			font-size: rem(40);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(24);
			max-width: rem(311);
			@media #{$nm} {
				font-size: rem(40);
			}
			@media #{$md} {
				min-width: unset;
			}
			@media #{$lgn} {
				font-size: rem(56);
				max-width: 100%;
			}
			@media #{$xxl} {
				font-size: rem(64);
				max-width: 100%;
			}
			@media #{$vl} {
				font-size: rem(90);
				padding-bottom: rem(30);
			}
		}
		h5 {
			font-family: $secondary;
			font-size: rem(16);
			font-weight: 800;
			line-height: 125%;
			letter-spacing: rem(2.4);
			text-transform: uppercase;
			margin-bottom: rem(16);
		}
		p {
			a {
				text-decoration: underline;
				&:hover {
					color: $brightYellow;
				}
			}
			@media #{$nm} {
				max-width: 90%;
			}
			@media #{$md} {
				max-width: 100%;
			}
		}

		@media #{$md} {
			width: 58.33%;
		}

		@media #{$lg} {
			padding: rem(112) rem(120);
		}

		@media #{$vl} {
			p {
				font-size: rem(25);
			}
		}

		&--design-2 {
			@media #{$md} {
				h1 {
					font-size: rem(48);
				}
			}
		}
	}

	.imageContent {
		width: 100%;
		height: auto;

		img {
			display: block;

			object-position: center;
			width: 100%;
			height: 50%;
		}
		@media #{$nm} {
			img {
				height: 90%;
			}
		}
		@media #{$md} {
			img {
				object-fit: cover;
				height: 100%;
			}
			h1 {
				min-width: rem(600);
			}

			width: 42%;
		}
	}
	&--imagePosition {
		@media #{$md} {
			flex-direction: row-reverse;
		}
	}
}
