@use '../../styles/shared/include' as *;

.sliderBoxWithDescription {
	background-color: $white;
	article {
		padding: rem(48) rem(20) rem(20);
		h3 {
			font-size: rem(40);
			padding-bottom: rem(24);
			font-weight: 300;
			line-height: 100%;

			@media #{$xl} {
				font-size: rem(72);
			}
		}
		p {
			font-size: rem(18);
			font-weight: 500;
			line-height: 140%;
			padding-bottom: rem(40);

			@media #{$xl} {
				padding-bottom: 0;
			}
		}
		@media #{$xl} {
			padding: rem(112) rem(120) rem(40) rem(120);
		}
	}
	.sliderBox {
		padding: rem(40) rem(120);
		--gap: 120px;
		position: relative;
		display: flex;
		overflow: hidden;
		user-select: none;
		gap: var(--gap);
		.sliderTrack {
			flex-shrink: 0;
			display: flex;
			justify-content: space-around;
			gap: var(--gap);
			min-width: 100%;
			animation: scroll 30s linear infinite;
			align-items: center;
			figure {
				img {
					height: auto;
					width: auto;
				}
			}
		}
	}

	@keyframes scroll {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(calc(-100% - var(--gap)));
		}
	}

	@media (prefers-reduced-motion: reduce) {
		.sliderBox .sliderTrack {
			animation-play-state: paused !important;
		}
	}
}
