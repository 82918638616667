@use '../../styles/shared/include' as *;

.shortBannerWithLink {
	background-color: $deepBlue;
	display: flex;
	flex-direction: column;
	padding: rem(40);
	color: white;
	gap: rem(40);
	@media #{$md} {
		flex-direction: row;
		justify-content: space-between;
		gap: 0;
	}
	@media #{$lgn} {
		padding: rem(53.15) rem(120);
	}

	.link {
		flex: 1;
		width: 100%;
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		text-decoration-line: underline;
	}
}
