@use '../../styles/shared/include' as *;
.leadership__hero {
	padding: rem(50) 0 rem(30);
	overflow: hidden;
	@media #{$md} {
		padding: rem(112) 0 rem(64);
	}
	:global {
		.container--max {
			position: relative;
		}
		.hero__logo-layer {
			position: absolute;
			right: rem(-2);
			top: calc(50% - 24px);
			transform: translateY(-50%);
			width: rem(100);
			display: none;
			@media #{$md} {
				display: block;
			}
			@media #{$lg} {
				width: rem(141);
				// height: rem(286);
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.hero__content {
			max-width: rem(620);
			@media #{$lg} {
				max-width: rem(800);
			}
			h4 {
				color: $deepBlue;
				font-size: rem(16);
				line-height: 125%;
				font-weight: 800;
				letter-spacing: rem(2);
				text-transform: uppercase;
				font-family: $secondary;
				margin-bottom: rem(20);
				@media #{$md} {
					letter-spacing: rem(2.4);
					margin-bottom: rem(24);
				}
			}
			h3 {
				color: $deepBlue;
				font-size: rem(48);
				line-height: 100%;
				font-weight: 300;
				margin-bottom: rem(20);
				@media #{$md} {
					font-size: rem(64);
					margin-bottom: rem(24);
				}
			}
			p {
				color: $deepBlue;
				font-size: rem(16);
				line-height: 140%;
				font-weight: 300;
				@media #{$md} {
					letter-spacing: rem(0.3);
					font-size: rem(18);
				}
				& + p {
					margin-bottom: rem(25.2);
				}
			}
		}
	}
}

.leaders__listing {
	:global {
		ul {
			display: flex;
			flex-wrap: wrap;
			border-top: rem(1) solid $periwinkleGrey;
			li {
				padding: rem(40) rem(20);
				width: calc(100% / 1);
				border-right: rem(1) solid $periwinkleGrey;
				border-bottom: rem(1) solid $periwinkleGrey;
				@media #{$sml-m} {
					border-right: 0;
				}
				@media #{$sml} {
					padding: rem(20);
					width: calc(100% / 2);
				}
				@media #{$md} {
					padding: rem(30);
					width: calc(100% / 2);
				}
				@media screen and (min-width: 640px) and (max-width: 1023px) {
					&:nth-child(2n + 0) {
						border-right: 0;
					}
				}
				@media #{$lg} {
					padding: rem(40);
					width: calc(100% / 3);
					&:nth-child(3n + 0) {
						border-right: 0;
					}
				}
				a {
					display: block;
				}
			}
		}
		hr {
			border-bottom: rem(1) solid $periwinkleGrey;
			margin: -1px 0 0;
		}
	}
}
