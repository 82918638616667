@use '../../styles/shared/include' as *;

.faqMediumComponent {
	padding: rem(40);
	@media #{$lgn} {
		padding: rem(112) rem(120) rem(97);
	}

	color: $deepBlue;
	.headContainer {
		padding-bottom: rem(40);
		h2 {
			font-size: rem(40);
			font-style: normal;
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(24);
			@media #{$lg} {
				font-size: rem(64);
			}
		}
		p {
			margin-bottom: rem(24);
		}
	}

	.faqContainer {
		list-style: none;
		padding-bottom: rem(40);
		display: grid;
		flex-wrap: wrap;

		li {
			border-top: rem(1) solid $deepBlue;
			padding-top: rem(40);
			margin-top: rem(40);

			h3 {
				margin-bottom: rem(8);
				font-size: rem(18);
				font-weight: 700;
				line-height: 140%;
			}

			p {
				margin: 0;
				a {
					text-decoration: underline;
					&:hover {
						color: $hoverBlue;
					}
				}
			}
			@media #{$lgn} {
				&:nth-child(odd) {
					margin-right: rem(20);
				}
				&:nth-child(even) {
					margin-left: rem(20);
				}
			}
		}
		@media #{$lgn} {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
