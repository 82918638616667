@use '../../styles/shared/include' as *;

.FranchiseNewsroom {
	:global {
		.FranchiseNewsroom__header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: rem(60) 0 rem(30);
			@media #{$md} {
				padding: rem(112) 0 rem(48);
			}
			&-text {
				width: 100%;
				@media #{$sml} {
					width: calc((596 / 1200) * 100%);
				}
				h2 {
					color: $deepBlue;
					font-weight: 300;
					line-height: 100%;
					font-size: rem(42);
					margin-bottom: rem(12);
					@media #{$md} {
						font-size: rem(50);
						margin-bottom: rem(24);
					}
					@media #{$xl} {
						font-size: rem(64);
					}
				}
				p {
					color: $deepBlue;
					font-weight: 500;
					line-height: 140%;
					font-size: rem(16);
					margin-bottom: 0;
					@media #{$md} {
						font-size: rem(18);
					}
				}
			}
			&-btn {
				margin-top: rem(20);
				@media #{$sml} {
					margin-top: 0;
				}
				a {
					color: $deepBlue;
					font-weight: 500;
					line-height: 110%;
					font-weight: rem(20);
					text-decoration: underline;
					font-size: rem(20);
					@include transition();
					&:hover {
						color: $hoverBlue;
						text-decoration-color: $hoverBlue;
					}
				}
			}
		}
		.FranchiseNewsroom__wrapper {
			ul {
				display: flex;
				flex-wrap: wrap;
				li {
					width: 100%;
					padding: rem(30) rem(20);
					border-right: rem(1) solid $periwinkleGrey;
					@media #{$sml} {
						width: 50%;
						padding: rem(30);
					}
					@media #{$sml-m} {
						border-right: none;
						border-bottom: rem(1) solid $periwinkleGrey;
					}
					@media #{$lg-m} {
						&:nth-child(1),
						&:nth-child(2) {
							border-bottom: rem(1) solid $periwinkleGrey;
						}
						&:nth-child(2) {
							border-right: none;
						}
					}
					@media #{$lg} {
						width: 25%;
					}
					@media #{$xl} {
						padding: rem(40);
					}
					p {
						color: $deepBlue;
						font-weight: 400;
						line-height: 150%;
						font-size: rem(16);
						margin-bottom: rem(12);
						@media #{$sml} {
							font-weight: 500;
							line-height: 100%;
							font-size: rem(20);
							margin-bottom: rem(24);
						}
					}
					a {
						color: $deepBlue;
						font-weight: 500;
						line-height: 140%;
						font-size: rem(16);
						text-decoration: underline;
						@include transition();
						&:hover {
							color: $hoverBlue;
							text-decoration-color: $hoverBlue;
						}
					}
					&:last-child {
						border: none;
					}
				}
			}
		}
	}
}
