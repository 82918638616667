@use '../../styles/shared/include' as *;

.methodsTextContainer {
	padding: rem(50) rem(30);
	color: $deepBlue;
	@media #{$xl} {
		padding: 0;
	}
	.HeadingSection {
		span {
			display: block;
		}
		h3 {
			max-width: rem(961);
		}
		h3 {
			font-size: rem(54);
			font-weight: 300;
			line-height: 100%;
		}
		@media #{$xl} {
			padding: rem(112) rem(120) rem(64) rem(120);
			h3 {
				font-size: rem(64);
			}
		}
	}
	.methodsSection {
		@media #{$xl} {
			display: flex;
			padding-left: rem(360);
			padding-right: rem(32);
			gap: rem(40);
			padding-bottom: rem(112);
		}
		.methodColumn {
			padding-top: rem(20);
			padding-bottom: rem(40);
			width: 100%;
			@media #{$xl} {
				padding: 0;
				padding-bottom: rem(40);
			}
			.methodNumber {
				width: rem(52);
				height: rem(49);
				background-color: $brightYellow;
				span {
					// padding: rem(16);
					padding-top: rem(15);
					padding-left: rem(20);
					font-size: rem(24);
					font-weight: 500;
					line-height: 100%;
					display: block;
				}
			}
			.methodColumnHeading {
				h4 {
					font-size: rem(28);
					font-weight: 300;
					line-height: rem(28);
					padding-top: rem(35);
				}
			}
			.methodColumnDescription {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				padding-top: rem(27);
			}
			.buttonContainer {
				padding-top: rem(18);
				@media #{$nm} {
					a {
						width: auto;
					}
				}
			}
		}
	}
}
