@use '../../styles/shared/include' as *;

.HeadingwithLongDescriptionBanner{
  padding: rem(60) 0;
  position: relative;
  @media #{$xl}{
    padding: rem(112) 0;
  }
  :global{
    .HeadingwithLongDescriptionBanner__content{
      width: rem(960);
      max-width: 100%;
      @media #{$md}{
        max-width: 90%;
      }
      h1{
        color: $deepBlue;
        line-height: 100%;
        font-weight: 300;
        font-size: rem(34);
        margin-bottom: rem(15);
        @media #{$lg}{
          font-size: rem(64);
          margin-bottom: rem(24);
        }
      }
      p{
        color: $black;
        font-weight: 400;
        line-height: 140%;
        font-size: rem(16);
        margin-bottom: 0;
        @media #{$lg}{
          font-weight: 500;
          font-size: rem(18);
        }

        &+p{
          margin-top: rem(15);
          @media #{$lg}{
            margin-top: rem(25);
          }
        }
      }
    }
    .section__layer{
      position: absolute;
      right: 0;
      top: rem(62);
      width: rem(159);
      display: none;
      @media #{$md}{
        width: rem(80);
        display: block;
      }
      @media #{$lg}{
        width: rem(120);
      }
      @media #{$xl}{
        width: rem(159);
      }
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}