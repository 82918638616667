@use './mixins' as *;
@use './variables' as *;

html,
body {
	font-family: 'DM Sans', sans-serif;
	color: $deepBlue;
	font-weight: 500;
	font-size: rem(16);
	font-feature-settings: 'lnum';
	-webkit-font-smoothing: antialiased;
	background-color: $white;
}

body {
	font-size: rem(18);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: 'DM Sans', sans-serif;
	font-weight: 500;
	word-wrap: break-word;
	line-height: 1.3;
	span {
		font-weight: 100;
		color: $brightYellow;
	}
	b {
		color: $brightYellow;
		font-weight: 300;
	}
}

strong {
	font-weight: 700;
}

p {
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
}
li {
	list-style: none;
}
img {
	display: block;
}

// label {
// 	color: $black;
// }
