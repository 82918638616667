@use '../../styles/shared/include' as *;

.boxDescription {
	// font-size: rem(18);

	.headWrapper {
		display: flex;
		flex-direction: column;
		height: fit-content;
		@media #{$lg} {
			flex-direction: row;
		}
	}

	.imageContainer {
		width: 100%;
		height: auto;
		img {
			width: 100%;
			height: auto;
		}
		@media #{$nm} {
			img {
				min-height: rem(350);
			}
		}
		@media #{$lg} {
			width: 42%;
			img {
				object-fit: cover;
				height: 100%;
			}
		}
	}

	.textContainer {
		color: $deepBlue;
		padding: rem(48) rem(20);
		h2 {
			font-size: rem(40);
			line-height: 100%;
			font-weight: 300;
			margin-bottom: rem(24);
		}
		span {
			display: block;
		}
		@media #{$lg} {
			align-self: center;
			width: 58.33%;
			padding: rem(80);
			h2 {
				font-size: rem(50);
			}
		}
		@media #{$xxl} {
			padding: rem(112) rem(120);
			h2 {
				font-size: rem(64);
			}
		}
		@media #{$vl} {
			h2 {
				font-size: rem(80);
				margin-bottom: rem(30);
			}
		}
	}

	.buttonContainer {
		gap: rem(12);
		margin-top: rem(24);
		display: flex;
		flex-direction: column;
		width: fit-content;
		@media #{$lg} {
			width: fit-content;
		}
	}

	.listWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-top: rem(1) solid $deepBlue;
		border-bottom: rem(1) solid $deepBlue;

		@media #{$lg} {
			flex-direction: row;
		}
		li {
			flex: 1;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: rem(1);
				height: 100%;
				top: 0;
				left: 0;
				background-color: $deepBlue;
				color: $deepBlue;
			}
			&::after {
				content: '';
				position: absolute;
				width: rem(1);
				height: 100%;
				top: 0;
				right: 0;
				background-color: $deepBlue;
				color: $deepBlue;
				@media #{$lg} {
					width: 0;
				}
			}
			&:last-child {
				&::after {
					content: '';
					position: absolute;
					width: rem(1);
					height: 100%;
					top: 0;
					right: 0;
					background-color: $deepBlue;
					color: $deepBlue;
				}
			}
		}
	}

	.listContent {
		flex: 1;
		padding: rem(48) rem(20);
		border-bottom: rem(1) solid $deepBlue;
		h3 {
			font-size: rem(28);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(24);
		}
		p {
			b {
				font-size: rem(18);
				font-style: normal;
				font-weight: 500;
				background-image: linear-gradient($brightYellow, $brightYellow);
				background-position: 0 calc(100% - rem(1));
				background-repeat: no-repeat;
				background-size: 0 100%;
				transition: 0.35s ease;
			}
		}
		@media #{$lg} {
			border-bottom: none;
		}
		@media #{$lgn} {
			padding: rem(40) rem(39.5);
		}

		@media #{$vl} {
			h5 {
				font-size: rem(35);
			}
			p {
				font-size: rem(20);
				width: auto;
			}
		}
		&:hover {
			p {
				b {
					font-style: normal;
					font-weight: 500;
					background-size: 100% 100%;
				}
			}
		}
	}
}
