@use '../../styles/shared/include' as *;

.PrivacyPolicy {
	:global {
		.page__hero {
			background-color: $periwinkleGrey;
			position: relative;
			.container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: rem(280);
				@media #{$md} {
					min-height: rem(380);
				}
				@media #{$lg} {
					min-height: rem(480);
				}
			}
			h1 {
				font-weight: 300;
				line-height: 100%;
				color: $deepBlue;
				font-size: rem(34);
				margin: 0;
				text-align: center;
				@media #{$md} {
					text-align: left;
				}
				@media #{$lg} {
					font-size: rem(64);
				}
			}
			.bannel__layer {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				display: none;
				@media #{$md} {
					width: rem(100);
					display: block;
				}
				@media #{$xl} {
					width: rem(159);
				}
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		.default__content {
			max-width: rem(900);
			margin: 0 auto;
			padding: rem(30) 0 rem(60);
			@media #{$lg} {
				padding: rem(64) 0 rem(112);
			}
			* {
				word-break: break-word;
			}
			p {
				color: $deepBlue;
				line-height: 140%;
				font-size: rem(16);
				font-weight: 400;
				margin-bottom: 0;
				@media #{$lg} {
					line-height: 177%;
					font-size: rem(18);
				}
				a {
					margin: 0 rem(3);
					text-decoration: underline;
					color: $deepBlue;
					&:hover {
						color: $black;
					}
				}
				b {
					font-weight: 600;
				}
				& + p {
					margin-top: rem(22);
					@media #{$lg} {
						margin-top: rem(32);
					}
				}
				&:empty {
					display: none;
				}
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin: rem(30) 0 rem(15);
				color: $deepBlue;
				line-height: 120%;
				font-weight: 300;
				b {
					color: $deepBlue;
					font-weight: 600;
				}
				@media #{$lg} {
					margin: rem(48) 0 rem(24);
				}
			}
			h1 {
				font-size: rem(30);
				@media #{$md} {
					font-size: rem(36);
				}
				@media #{$lg} {
					font-size: rem(41);
				}
				@media #{$xl} {
					font-size: rem(46);
				}
			}
			h2 {
				font-size: rem(27);
				@media #{$md} {
					font-size: rem(32);
				}
				@media #{$lg} {
					font-size: rem(36);
				}
				@media #{$xl} {
					font-size: rem(40);
				}
			}
			h3 {
				font-size: rem(24);
				@media #{$md} {
					font-size: rem(28);
				}
				@media #{$lg} {
					font-size: rem(32);
				}
				@media #{$xl} {
					font-size: rem(36);
				}
			}
			h4,
			h5,
			h6 {
				font-size: rem(18);
				@media #{$md} {
					font-size: rem(22);
				}
				@media #{$lg} {
					font-size: rem(26);
				}
				@media #{$xl} {
					font-size: rem(30);
				}
			}
			h5,
			h6 {
				font-weight: 400;
			}
			ul {
				margin: rem(20) 0;
				padding-left: rem(16);
				@media #{$lg} {
					margin: rem(32) 0;
					padding-left: rem(25);
				}
				li {
					list-style: disc;
					color: $deepBlue;
					line-height: 166%;
					font-size: rem(16);
					font-weight: 400;
					margin-bottom: 0;
					@media #{$lg} {
						line-height: 177%;
						font-size: rem(18);
					}
				}
			}
			table {
				border: 1px solid $periwinkleGrey;
				border-collapse: collapse;
				text-align: left;
				margin: rem(12) 0;
				@media #{$lg} {
					margin: rem(24) 0;
				}
				th {
					background-color: $deepBlue;
					padding: rem(6);
					vertical-align: top;
					@media #{$sml} {
						padding: rem(10);
					}
					@media #{$lg} {
						padding: rem(16) rem(24) rem(24);
						min-width: rem(240);
					}
					p {
						color: $white;
						line-height: 110%;
						font-size: rem(18);
						font-weight: 600;
						margin-bottom: 0;
						@media #{$lg} {
							line-height: 120%;
							font-size: rem(24);
						}
					}
					b {
						font-weight: 600;
					}
				}
				td {
					border: 1px solid $periwinkleGrey;
					border-collapse: collapse;
					vertical-align: top;
					min-width: rem(60);
					padding: rem(10);
					padding: rem(6);
					@media #{$sml} {
						padding: rem(10);
						min-width: rem(135);
					}
					@media #{$lg} {
						padding: rem(16) rem(24) rem(24);
					}
				}
			}
		}
	}
}
