@use './variables' as *;
@use './mixins' as *;
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	font-family: $primary;
	font-size: rem(18);
	font-style: normal;
	font-weight: 500;
	line-height: rem(20);
	[data-aos='fade-up'] {
		transform: translate3d(0, 60px, 0);
	}
	[data-aos='fade-left'] {
		transform: translate3d(60px, 0, 0);
	}
}

a {
	color: inherit;
	text-decoration: none;
	display: inline-block;
	// background-color: transparent;
	// line-height: 0;
}
.container {
	max-width: 100%;
	margin: 0 auto;
	padding: 0 rem(120);
	@media screen and (max-width: 1200px) {
		padding: 0 rem(60);
	}
	@media screen and (max-width: 992px) {
		padding: 0 rem(20);
	}
	&--fluid {
		padding: 0 rem(32);
		@media screen and (max-width: 1200px) {
			padding: 0;
		}
	}
	&--max {
		// max-width: 1440px;
		padding: 0;
	}
}
