@use '../../styles/shared/include' as *;
.sliderBoxContainer {
	background-color: $cream;
	header {
		padding-top: rem(40);
		display: flex;
		justify-content: center;
		h2 {
			color: $deepBlue;
			font-weight: 800;
			line-height: 125%;
			letter-spacing: rem(2.4);
			font-family: $secondary;
			font-size: rem(16);
			text-transform: uppercase;
			text-align: center;
			padding: 0 rem(5);
		}
	}
	.sliderBox {
		padding: rem(16) 0 rem(40);
		--gap: 120px;
		position: relative;
		display: flex;
		overflow: hidden;
		user-select: none;
		gap: var(--gap);
		.sliderTrack {
			flex-shrink: 0;
			display: flex;
			justify-content: space-around;
			gap: var(--gap);
			min-width: 100%;
			animation: scroll 30s linear infinite;
			align-items: center;
			figure {
				img {
					height: auto;
					width: auto;
					min-width: 120px;
					min-height: 40px;
					mix-blend-mode: screen;
				}
			}
		}
	}

	@keyframes scroll {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(calc(-100% - var(--gap)));
		}
	}

	@media (prefers-reduced-motion: reduce) {
		.sliderBox .sliderTrack {
			animation-play-state: paused !important;
		}
	}
	.sliderBox {
		mix-blend-mode: multiply !important;
	}
}
