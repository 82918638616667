@use '../../styles/shared/include' as *;

.ReasonsComponent {
	padding: rem(60) 0;
	@media #{$md} {
		padding: rem(112) 0;
	}
	:global {
		.ReasonsComponent__header {
			h2 {
				margin: 0;
				color: $deepBlue;
				line-height: 100%;
				font-weight: 300;
				font-size: rem(32);
				@media #{$md} {
					font-size: rem(50);
				}
				@media #{$xl} {
					font-size: rem(64);
				}
			}
			.headerTagline {
				font-family: $secondary;
				color: $deepBlue;
				font-weight: 800;
				line-height: 125%;
				text-transform: uppercase;
				font-size: rem(16);
				letter-spacing: rem(2.4);
				margin-bottom: rem(16);
				display: block;
			}
		}
		.container--max {
			padding: 0 rem(20);
			display: flex;
			justify-content: flex-end;
			@media #{$md} {
				padding: 0 rem(32);
			}
		}
		.ReasonsComponent__row {
			max-width: rem(1086);
			display: flex;
			flex-wrap: wrap;
			@media #{$sml} {
				margin: 0 rem(-20);
			}
			.ReasonsComponent__item {
				width: 100%;
				margin-top: rem(30);
				@media #{$sml} {
					width: calc(100% / 2);
					padding: 0 rem(20);
				}
				@media #{$md} {
					width: calc(100% / 3);
					margin-top: rem(64);
				}
				.ReasonsComponent__year {
					display: inline-block;
					background-color: $brightYellow;
					padding: rem(10);
					margin-bottom: rem(20);
					font-weight: 500;
					line-height: 100%;
					color: $deepBlue;
					font-size: rem(18);
					@media #{$md} {
						font-size: rem(24);
						padding: rem(12.8) rem(16);
						margin-bottom: rem(34);
					}
				}
				figure {
					background-color: $brightYellow;
					padding: rem(10);
					width: rem(40);
					margin-bottom: rem(20);
					@media #{$md} {
						padding: rem(16);
						width: rem(56);
						margin-bottom: rem(34);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
				h3 {
					font-weight: 300;
					line-height: 100%;
					color: $deepBlue;
					font-size: rem(24);
					margin-bottom: rem(12);
					@media #{$md} {
						font-size: rem(28);
						margin-bottom: rem(24);
					}
					&:empty {
						display: none;
					}
				}
				p {
					color: $deepBlue;
					font-weight: 500;
					line-height: 140%;
					font-size: rem(16);
					margin-bottom: rem(10);
					@media #{$md} {
						font-size: rem(18);
					}
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
