@use '../../styles/shared/include' as *;

.BigBannerTextColContainerSection {
	background-color: $deepBlue;
	.BigBannerTextColContainer {
		height: fit-content;
		@media #{$xl} {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row-reverse;
			padding: rem(112) 0 rem(70) rem(120);
		}
		.ImageSection {
			width: 100%;
			@media #{$xl} {
				max-width: 63.63%;
			}
			img {
				height: 100%;
				object-fit: cover;
				object-position: center;
				// aspect-ratio: (840/720);
				width: 100%;
			}
		}
		.TextButtonSection {
			padding: rem(40) rem(30);
			color: $white;
			@media #{$md} {
				padding: rem(60) rem(60);
			}
			@media #{$xl} {
				max-width: 36.37%;
				padding: 0;
				padding-right: rem(120);
				align-self: flex-end;
			}
			h3 {
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				@media #{$lg} {
					font-size: rem(64);
				}
			}
			p {
				padding-top: rem(24);
				font-size: rem(18);
				font-weight: 400;
				line-height: 140%;
				@media #{$lg} {
					min-width: rem(360);
				}
			}
			ul {
				padding-top: rem(16);
				li {
					p {
						padding-top: 0 !important;
					}
				}
			}
			h5 {
				color: $brightYellow;
			}
			.ButtonContainer {
				display: flex;
				flex-direction: column;
				padding-top: rem(40);
				gap: rem(12);
				align-items: flex-start;
				@media #{$md} {
					a {
						width: auto;
					}
				}
			}
		}
	}
}
