@use '../../styles/shared/include' as *;

.ComparisonBox {
	padding: rem(60) 0;
	background-color: $periwinkleGrey;
	@media #{$lg} {
		padding: rem(112) 0;
	}
	:global {
		.container--max {
			padding: 0 rem(20);
			@media #{$lg} {
				padding: 0 rem(32);
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0 rem(-10);
			@media #{$lg} {
				margin: 0;
				gap: calc((24 / 1376) * 100%);
			}

			li {
				width: 100%;
				padding: 0 rem(10);
				margin-bottom: rem(20);
				@media #{$md} {
					width: calc(100% / 3);
					margin-bottom: 0;
				}
				@media #{$lg} {
					padding: 0;
					width: calc((333.33 / 1376) * 100%);
				}
				.ComparisonBox__head {
					padding: rem(30) rem(20);
					background-color: $deepBlue;
					border-top-left-radius: rem(12);
					border-top-right-radius: rem(12);
					@media #{$md} {
						min-height: rem(170);
					}
					@media #{$lg} {
						min-height: rem(210);
						padding: rem(40) rem(20);
					}
					@media #{$xxl} {
						padding: rem(40);
					}
					h3 {
						color: $white;
						font-weight: 400;
						line-height: 120%;
						font-size: rem(18);
						margin-bottom: rem(20);
						@media #{$md} {
							min-height: rem(50);
						}
						@media #{$lg} {
							font-size: rem(20);
						}
						@media #{$lgn} {
							margin-bottom: rem(24);
							min-height: rem(58);
							font-size: rem(24);
						}
					}
					h2 {
						color: $brightYellow;
						font-weight: 300;
						line-height: 100%;
						font-size: rem(26);
						margin-bottom: rem(4);
						@media #{$lg} {
							font-size: rem(38);
						}
						@media #{$lgn} {
							font-size: rem(50);
						}
					}
					p {
						color: $white;
						font-weight: 400;
						line-height: 100%;
						font-size: rem(16);
						margin-bottom: 0;
						@media #{$lgn} {
							font-size: rem(18);
						}
					}
				}
				.ComparisonBox__row {
					position: relative;
					background-color: $white;
					padding: rem(20);
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: rem(80);
					@media #{$lgn} {
						min-height: rem(114);
					}
					@media #{$xxl} {
						padding: rem(40);
					}
					&::after {
						content: '';
						position: absolute;
						bottom: 0;
						right: 0;
						width: 100%;
						border-bottom: rem(1) solid $deepBlue;
						@media #{$lg} {
							width: calc(100% + 50px);
						}
					}
					.row__title {
						color: $deepBlue;
						font-weight: 600;
						line-height: 120%;
						font-size: rem(16);
						margin-bottom: rem(6);
						display: block;
						@media #{$md} {
							display: none;
						}
					}
					span {
						color: $deepBlue;
						font-weight: 400;
						line-height: 140%;
						font-size: rem(20);
						margin: 0;
						@media #{$lgn} {
							font-size: rem(24);
						}
					}
					.benefit__list {
						padding-left: rem(30);
						display: block;
						margin: 0;
						@media #{$lgn} {
							padding-left: rem(20);
						}
						@media #{$xxl} {
							padding-left: rem(29);
						}
						li {
							width: 100%;
							list-style-type: disc;
							font-weight: 400;
							line-height: 161%;
							margin: 0;
							font-size: rem(16);
							padding: 0;
							@media #{$lgn} {
								font-size: rem(18);
							}
						}
					}
					&:last-child {
						border-bottom-left-radius: rem(12);
						border-bottom-right-radius: rem(12);
						&::after {
							display: none;
						}
					}
				}
				&.ComparisonBox__title {
					width: 100%;
					@media #{$lg} {
						width: calc((304 / 1376) * 100%);
					}
					.ComparisonBox__head {
						background-color: transparent;
						padding: 0 0 rem(30);
						min-height: unset;
						@media #{$lg} {
							padding: 0;
							min-height: rem(210);
						}
						@media #{$lgn} {
							min-height: rem(234);
						}
						figure {
							width: rem(45);
							margin-bottom: rem(20);
							@media #{$lgn} {
								width: rem(53);
								margin-bottom: rem(38);
							}
							img {
								width: 100%;
								height: auto;
							}
						}
						h2 {
							font-family: $secondary;
							color: $deepBlue;
							font-weight: 700;
							line-height: 120%;
							letter-spacing: rem(0.8);
							text-transform: uppercase;
							font-size: rem(16);
							margin: 0;
							@media #{$lg} {
								max-width: rem(200);
							}
						}
					}
					.ComparisonBox__row {
						background-color: transparent;
						padding: 0;
						display: none;
						@media #{$lg} {
							display: flex;
						}
						&::after {
							width: 100%;
						}
						span {
							color: $deepBlue;
							font-weight: 400;
							line-height: 130%;
							font-size: rem(16);
							margin: 0;
							@media #{$lgn} {
								font-weight: 600;
								line-height: 161%;
								font-size: rem(18);
							}
						}
					}
				}
			}
		}
	}
}
