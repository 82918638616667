@use './shared/' as *;

html {
	scroll-behavior: smooth;
}
body {
	-webkit-tap-highlight-color: transparent;
}

.isDesktop {
	display: none;
	@media #{$lgn} {
		display: block;
	}
}

.isMobile {
	display: block;
	@media #{$lgn} {
		display: none;
	}
}

.disableScroll {
	overflow: hidden;
}

.headingTagline {
	font-family: $secondary;
	color: $deepBlue;
	font-size: rem(16);
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: rem(2.4);
	margin-bottom: rem(16);
	line-height: rem(20);
	&--yellow {
		color: $brightYellow;
	}
}
// <div class="aspect-box" style="--aspect-ratio:9/16">
.aspect-box {
	position: relative;
	width: 100%;
	overflow: hidden;
	img,
	video,
	canvas,
	iframe,
	svg {
		height: 101%;
		left: 0;
		position: absolute;
		top: 0;
		width: 101%;
		object-fit: cover;
	}
	&:before {
		content: '';
		display: block;
		padding-top: calc(var(--aspect-ratio) * 100%);
		background-color: $deepBlue;
	}
}

// page loader
.is__pageloader.is__fill {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $deepBlue;
	z-index: 2;
	figure {
		width: rem(60);
		@media #{$lg} {
			width: rem(100);
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}
.is__pageloader.is__center {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	z-index: 2;
	figure {
		width: rem(60);
		@media #{$lg} {
			width: rem(100);
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

.error {
	color: red;
	font-size: rem(16);
	font-weight: rem(500);
}

.swiper-wrapper {
	transition-timing-function: linear !important;
}

.gm-style-iw,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after {
	background: $deepBlue;
}

.gm-ui-hover-effect > span {
	background-color: $white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
