@use '../../styles/shared/include' as *;

.miniBoxDescription {
	background-color: $deepBlue;

	.headWrapper {
		display: flex;
		flex-direction: column;

		height: fit-content;
		padding: rem(48) rem(20);
		@media #{$md} {
			flex-direction: row;
			justify-content: space-between;
			padding: rem(64);
			align-items: center;

			&--imagePosition {
				flex-direction: row-reverse;
			}
		}
		@media #{$lgn} {
			padding: rem(112) rem(120) rem(64);
		}
	}

	.textContainer {
		color: $white;
		h2 {
			font-size: rem(40);
			line-height: 100%;
			font-weight: 300;
			margin-bottom: rem(24);
		}
		@media #{$md} {
			max-width: rem(800);

			h2 {
				font-size: rem(64);
			}
		}
	}

	.listWrapper {
		display: flex;
		flex-direction: column;

		justify-content: space-between;
		@media #{$lgn} {
			flex-direction: row;
		}
	}

	.listContent {
		flex: 1;
		position: relative;

		border-top: rem(1) solid $white;
		padding: rem(30);
		color: $white;

		h3 {
			color: $brightYellow;
			font-size: rem(28);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(24);
		}

		@media #{$md} {
			padding: rem(64);
			border-bottom: rem(1) solid $white;
			min-width: rem(232);
			&::after {
				content: '';
				position: absolute;
				background-color: $white;
				width: rem(1);
				height: 100%;
				top: 0;
				right: 0;
			}
		}
	}
}
