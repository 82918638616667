@use '../../styles/shared/include' as *;

.WithNumberCard {
	background-color: $deepBlue;
	padding: rem(60) 0;
	@media #{$md} {
		padding: rem(112) 0;
	}
	:global {
		.WithNumberCard__row {
			display: flex;
			flex-wrap: wrap;
			.WithNumberCard__col-title {
				display: flex;
				justify-content: space-between;
				width: 100%;
				flex-direction: column-reverse;
				gap: rem(20);
				@media #{$md} {
					gap: 0;
					flex-direction: column;
					padding-right: rem(30);
					width: 50%;
				}
				h3 {
					color: $white;
					font-weight: 300;
					line-height: 100%;
					font-size: rem(34);
					@media #{$md} {
						font-size: rem(48);
					}
					@media #{$xl} {
						font-size: rem(64);
						max-width: rem(462);
					}
				}
				figure {
					width: rem(48);
					@media #{$md} {
						width: rem(52.29);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			.WithNumberCard__col-number {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: rem(32);
				margin-top: rem(40);
				@media #{$md} {
					width: 50%;
					gap: rem(64);
					margin-top: 0;
				}
				.countUpContainer {
					color: $brightYellow;
					line-height: 100%;
					font-weight: 300;
					font-size: rem(48);
					margin-bottom: rem(8);
					display: block;
					@media #{$sm} {
						font-size: rem(60);
					}

					@media #{$xl} {
						font-size: rem(90);
					}
					@media #{$xxl} {
						font-size: rem(120);
					}
				}
				p {
					color: $white;
					font-weight: 300;
					line-height: 100%;
					font-size: rem(18);
					margin: 0;
					@media #{$md} {
						font-size: rem(28);
					}
				}
			}
		}
	}
}
