@use '../../styles/shared/include' as *;

.you__covered {
	background-color: $deepBlue;
	padding: rem(60) rem(0) rem(40);
	@media #{$md} {
		padding: rem(112) rem(0);
	}
	:global {
		h2 {
			font-size: rem(28);
			color: $white;
			font-weight: 300;
			line-height: 100%;
			margin-bottom: 0;
			@media #{$md} {
				font-size: rem(48);
			}
		}
		.headingTagline {
			font-size: rem(16);
			color: $brightYellow;
			font-weight: 800;
			letter-spacing: rem(2.4);
			text-transform: uppercase;
			font-family: $secondary;
			margin-bottom: rem(8);
			line-height: 125%;
			display: block;
			@media #{$md} {
				margin-bottom: rem(16);
			}
		}
		.you__covered-row {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-around;
			.you__covered-item {
				padding: rem(20);
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				text-align: center;
				gap: rem(8);
				@media #{$md} {
					padding: rem(40);
					width: 25%;
				}
				figure {
					width: rem(70);
					@media #{$md} {
						width: rem(100);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
				p {
					font-size: rem(18);
					color: $white;
					font-weight: 400;
					line-height: 120%;
					margin: 0;
				}
				a, button {
					background-color: transparent;
					border: transparent;
					font-size: rem(18);
					color: $white;
					font-weight: 400;
					line-height: 120%;
					margin-bottom: 0;
					text-decoration: underline;
					@include transition();
					&:hover {
						color: $brightYellow;
					}
				}
			}
		}
	}
}
