@use '../../styles/shared/include' as *;

.halfTextImagehalfForm {
	@media #{$lg} {
		padding: rem(50) 0;
		padding: rem(112) 0;
	}
	:global {
		.halfTextImagehalfForm__row {
			display: flex;
			flex-wrap: wrap;
		}
		.imageTextContainer {
			position: relative;
			width: 100%;
			@media #{$lg} {
				width: calc(50% + 10px);
			}
			.imageTextContainer__col {
				display: flex;
				flex-direction: column;
				position: sticky;
				top: rem(100);
			}
			figure {
				padding-top: rem(20);
				@media #{$lg} {
					order: 2;
					max-width: rem(600);
					padding-top: 0;
				}
				img {
					width: 100%;
					height: auto;
				}
			}
			article {
				margin-top: rem(20);
				@media #{$lg} {
					order: 1;
					margin-top: 0;
					margin-bottom: rem(48);
				}
				span {
					display: block;
				}
				h3 {
					line-height: 100%;
					font-weight: 300;
					color: $deepBlue;
					padding-bottom: rem(10);
					font-size: rem(32);
					@media #{$lg} {
						padding-bottom: rem(16);
						font-size: rem(64);
					}
				}
				p {
					font-size: rem(16);
					line-height: 140%;
					font-weight: 500;
					color: $deepBlue;
					@media #{$lg} {
						font-size: rem(18);
					}
				}
			}
		}
		.formContainer {
			position: relative;
			width: 100%;
			padding-top: rem(42);
			padding-bottom: rem(30);
			@media #{$lg} {
				width: calc(50% - 11px);
				padding-top: 0;
				padding-left: rem(80);
			}
			@media #{$xl} {
				padding-left: rem(110);
			}
			& > div {
				width: 100%;
			}
			fieldset {
				width: 100%;
				& > div {
					display: flex;
					flex-direction: column;
				}
				label {
					margin-bottom: rem(4);
					font-size: rem(14);
					font-weight: 500;
					line-height: 125%;
					color: $black;
					@media #{$lg} {
						font-size: rem(16);
					}
				}
				input {
					font-size: rem(16);
					font-weight: 400;
					line-height: 125%;
					color: $black;
					padding: rem(10) rem(12);
					border-radius: rem(3);
					background-color: $white;
					border: 1px solid $periwinkleGrey;

					box-shadow: none;

					@media #{$lg} {
						padding: rem(11) rem(23);
					}
				}
				select {
					font-size: rem(16);
					font-weight: 400;
					line-height: 125%;
					color: $black;
					border-radius: rem(3);
					background-color: $white;
					border: 1px solid $periwinkleGrey;
					width: 100%;
					box-shadow: none;
					padding: rem(10) rem(12);

					background-image: linear-gradient(
							45deg,
							transparent 50%,
							rgb(0, 0, 0) 50%
						),
						linear-gradient(135deg, rgb(0, 0, 0) 50%, transparent 50%);
					background-position: calc(100% - 20px) calc(1em + 2px),
						calc(100% - 15px) calc(1em + 2px), 100% 0;
					background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
					background-repeat: no-repeat;
					// -webkit-appearance: none;
					cursor: pointer;
					@media #{$lg} {
						padding: rem(11) rem(23);
					}
				}
				p {
					margin-bottom: rem(16);
					font-size: rem(16);
					font-weight: 500;
					line-height: 140%;
					color: $deepBlue;
					@media #{$lg} {
						margin-bottom: rem(24);
						font-size: rem(18);
					}
				}
				textarea {
					font-size: rem(16);
					font-weight: 400;
					line-height: 125%;
					color: $black;
					font-family: 'Arial';
					min-height: rem(80);
					padding: rem(10) rem(12);
					border-radius: rem(3);
					background-color: $white;
					border: 1px solid $periwinkleGrey;

					box-shadow: none;

					@media #{$lg} {
						min-height: rem(104);
						padding: rem(11) rem(23);
					}
				}
				a {
					min-width: rem(200);
					padding-top: rem(5);
				}
			}
		}
	}
}
