@use '../../styles/shared/include' as *;

.methodsTextContainer {
	background-color: $deepBlue;
	color: $white;
	overflow: hidden;
	:global {
		.HeadingSection {
			border-bottom: rem(1) solid $white;
		}
		.container--max {
			position: relative;
			.logo__layer {
				position: absolute;
				right: rem(-2);
				top: 58%;
				transform: translateY(-50%);
				img {
					max-width: rem(100);
					display: none;
					@media #{$md} {
						display: block;
					}
					@media #{$lg} {
						max-width: rem(141);
					}
				}
			}
		}
		.HeadingWrapper {
			padding: rem(80) 0;
			width: rem(960);
			max-width: 100%;
			@media #{$md} {
				max-width: 80%;
			}
			@media #{$lg} {
				padding: rem(112) 0;
			}
			.headingTagline {
				font-size: rem(16);
				line-height: 125%;
				font-weight: 800;
				font-family: $secondary;
				color: $brightYellow;
				margin-bottom: rem(16);
				display: block;
			}
			.headingTitle {
				font-size: rem(36);
				line-height: 100%;
				color: $white;
				font-weight: 300;
				@media #{$lg} {
					font-size: rem(64);
				}
			}
		}

		.methodsSection {
			display: flex;
			flex-wrap: wrap;
			width: calc(100% + 2px);
			margin-right: rem(-2);
			.methodColumn {
				width: 100%;
				padding: rem(30) rem(20);
				border: none;
				border-bottom: rem(1) solid $white;
				@media #{$md} {
					border-right: rem(1) solid $white;
					width: 50%;
				}
				@media #{$xl} {
					width: 20%;
					padding: rem(40);
				}
				&:last-child {
					// border-right: none;
				}
				.methodColumnHeading {
					margin-bottom: rem(10);
					@media #{$xl} {
						margin-bottom: rem(24);
					}
					span {
						font-size: rem(40);
						font-weight: 300;
						line-height: 100%;
						color: $brightYellow;
						display: block;
						@media #{$xl} {
							font-size: rem(64);
						}
					}
				}
				.methodColumnDescription {
					font-size: rem(18);
					font-weight: 500;
					line-height: 140%;
					color: $white;
					b {
						display: block;
						color: $brightYellow;
						font-weight: 500;
					}
				}
			}
		}
	}
}
