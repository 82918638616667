@use '../../styles/shared/include' as *;

.ProductList{
  padding: rem(60) 0;
  background-color: $deepBlue;
  @media #{$lg}{
    padding: rem(112) 0 rem(104);
  }
  :global{
    h2{
      color: $white;
      font-weight: 300;
      line-height: 100%;
      font-size: rem(34);
      margin-bottom: rem(30);
      @media #{$lg}{
        font-size: rem(64);
        margin-bottom: rem(64);
      }
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      margin: 0 rem(-5);
      @media #{$lg}{
        margin: 0 rem(-8);
      }
      li{
        padding: 0 rem(5);
        margin-bottom: rem(10);
        width: 50%;
        @media #{$sml}{
          width: 33.33%;
        }
        @media #{$md}{
          width: 25%;
        }
        @media #{$lg}{
          margin-bottom: rem(16);
          padding: 0 rem(8);
          width: 20%;
        }
        .ProductList__item{
          border-radius: rem(6);
          border: rem(1) solid $periwinkleGrey;
          padding: rem(10);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media #{$lg}{
            padding: rem(12);
          }
          figure{
            width: rem(50);
            margin-bottom: rem(2.4);
            @media #{$lg}{
              width: rem(64);
            }
            img{
              width: 100%;
              height: auto;
            }
          }
          p{
            color: $white;
            font-weight: 300;
            line-height: 140%;
            font-size: rem(16);
            margin: 0;
            @media #{$lg}{
              font-size: rem(20);
            }
          }
        }
      }
    }
  }
}